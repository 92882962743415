import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import singInMutation from '../apollo/mutations/singIn.graphql';
import routes from '../common/routes';
import errorMessages from '../common/errorMessages';
import withRoot from './withRoot';
import AppForm from './common/AppForm';
import useTranslation from './common/useTranslation';
import { UserContext } from './context/UserContext';

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
}));

function SignIn({ history }) {
  const classes = useStyles();
  const translate = useTranslation();
  const [login] = useMutation(singInMutation);
  const [formState, setFormState] = useState({ email: '', password: '' });
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const { loggedIn, setUserState, setLanguage } = useContext(UserContext);
  const currentUser = JSON.parse(localStorage.getItem('user'));

  if (loggedIn && currentUser) {
    history.push('/');
  }

  const handleSubmit = async event => {
    event.preventDefault();
    let result = null;
    try {
      result = await login({
        variables: {
          email: formState.email,
          password: formState.password,
        },
      });
      if (result) {
        const user = {
          userid: result.data.signIn.userId,
          token: result.data.signIn.token,
          roles: result.data.signIn.roles,
          language: result.data.signIn.language,
        };
        localStorage.setItem('user', JSON.stringify(user));
        setUserState(user);
        setLanguage(result.data.signIn.language);
        history.push('/');
      }
    } catch (err) {
      if (err.message.includes(errorMessages.noUserFound)) {
        setEmailError('noUserFound');
      }
      if (err.message.includes(errorMessages.invalidPassword)) {
        setPasswordError('invalidPassword');
      }
    }
  };

  const handleInputChange = event => {
    const { value, name } = event.target;
    setFormState({ ...formState, [name]: value });

    if (emailError && name === 'email') {
      setEmailError('');
    }

    if (passwordError && name === 'password') {
      setPasswordError('');
    }
  };

  return (
    <Wrapper>
      <AppForm>
        <h3>
          {translate('signIn')}
          <hr />
        </h3>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            error={!!emailError}
            helperText={emailError && translate(emailError)}
            placeholder={translate('login')}
            type="email"
            name="email"
            onChange={handleInputChange}
            value={formState.email}
          />
          <br /> <br />
          <TextField
            fullWidth
            error={!!passwordError}
            helperText={passwordError && translate(passwordError)}
            placeholder={translate('password')}
            type="password"
            name="password"
            onChange={handleInputChange}
            value={formState.password}
          />
          {/* <button type="submit" color="secondary" className={classes.button}> */}
          {/*  Login */}
          {/* </button> */}
          <Button
            color="secondary"
            fullWidth
            // eslint-disable-next-line react/display-name,react/no-multi-comp
            component={React.forwardRef((props, ref) => (
              <button {...props} ref={ref} type="submit" />
            ))}
            className={classes.button}
          >
            Login
          </Button>
        </form>
        <Link
          underline="always"
          href={`/#${routes.forgotPassword}`}
          style={{ color: 'white' }}
        >
          {translate('forgotPassword')}
        </Link>
      </AppForm>
    </Wrapper>
  );
}

SignIn.propTypes = {
  history: PropTypes.object.isRequired,
};

const Wrapper = styled.div`
  h3 {
    font-size: 42px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    line-height: 1.04;
    text-transform: uppercase;
    text-align: center;
    margin: 20px 0;
    hr {
      width: 55px;
      height: 4px;
      margin: 8px auto 0;
      display: block;
      background-color: rgb(33, 64, 101);
      border: none;
    }
  }
  .MuiInputLabel-root {
    color: white !important;
  }

  form {
    margin-top: 20px;
  }

  input {
    width: calc(100% - 44px);
    padding: 22px;
    font-size: 18px;
    background-color: white;
    color: #1e1e1f;
  }
  .MuiInput-underline:after {
    border-color: rgb(33, 64, 101);
  }
`;

export default withRoot(SignIn);
