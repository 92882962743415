import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import React, { Fragment } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import MainDivider from '../../common/MainDivider';
import MainHeader from '../../common/MainHeader';
import useTranslation from '../../common/useTranslation';
import getProjectByNameQuery from '../../../apollo/queries/getProjectByName.graphql';
import updateProjectMutation from '../../../apollo/mutations/updateProject.graphql';
import ProjectForm from '../../Settings/common/ProjectForm';
import Loading from '../../../common/Loading';
import historyShape from '../../../common/shapes/historyShape';
import ProjectUserList from './ProjectUserList';
import DownloadUserList from './DownloadUserList';

const useStyles = makeStyles(theme => ({
  main: {
    width: '100%',
    backgroundColor: theme.palette.primary.dark,
  },
  root: {
    backgroundColor: theme.palette.primary.dark,
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    padding: 15,
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    minHeight: '60rem',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
}));

function Settings({ match, history }) {
  const classes = useStyles();
  const translate = useTranslation();
  const { projectName } = match.params;
  const { data, loading } = useQuery(getProjectByNameQuery, {
    variables: {
      projectName,
    },
  });

  const [updateProject] = useMutation(updateProjectMutation);

  if (loading) {
    return <Loading />;
  }

  const project = get(data, 'getProjectByName', null);
  const cleanProject = {
    _id: project._id,
    name: project.name,
    isFilmSet: project.isFilmSet,
  };

  if (project.columns) {
    cleanProject.columns = project.columns.join(',');
  }

  const handleProjectUpdate = async updatedProject => {
    await updateProject({
      variables: updatedProject,
    });
    history.push('/');
  };

  return (
    <div className={classes.root}>
      {loading ? null : (
        <Fragment>
          <MainHeader text={`${translate('settings')} - ${projectName}`} />
          <MainDivider />
          <Container maxWidth="md">
            <Paper className={classes.paper}>
              <ProjectUserList project={project} />
              <DownloadUserList project={project} />
              <ProjectForm
                project={cleanProject}
                handleSubmit={handleProjectUpdate}
              />
            </Paper>
          </Container>
        </Fragment>
      )}
    </div>
  );
}

Settings.propTypes = {
  match: PropTypes.object.isRequired,
  history: historyShape.isRequired,
};

export default withRouter(Settings);
