import React, { useContext, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { UserContext } from '../context/UserContext';
import { generateProjectPath } from '../../common/routes';
import historyShape from '../../common/shapes/historyShape';
import projectShape from '../../common/shapes/projectShape';
import useTranslation from '../common/useTranslation';
import ProjectsListMenu from './ProjectsListMenu';

const avatarSize = 80;

const useStyles = makeStyles(theme => ({
  listItem: {
    borderBottom: `${theme.palette.primary.light} solid 2px`,
    minHeight: theme.spacing(20),
    padding: theme.spacing(3, 4),
  },
  withHover: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.light,
    },
  },
  noBorder: {
    borderBottom: 'none',
  },
  importing: {
    position: 'absolute',
    left: theme.spacing(4),
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    textAlign: 'center',
    width: `${avatarSize + 4}px`,
  },
  spinner: {
    position: 'absolute',
    height: `${avatarSize}px !important`,
    width: `${avatarSize}px !important`,
  },
  avatarItem: {
    minWidth: `${avatarSize * 1.25}px`,
  },
  avatar: {
    height: `${avatarSize}px`,
    width: `${avatarSize}px`,
  },
}));

function ProjectsListSingleRow({ history, project, isLastChild }) {
  const [importInProgress, setImportInProgress] = useState(
    project.importInProgress,
  );

  const goToProject = projectName =>
    !importInProgress && history.push(generateProjectPath(projectName));
  const { isAdmin } = useContext(UserContext);
  const translate = useTranslation();
  const classes = useStyles();

  const getItemClassName = () => {
    let className = classes.listItem;
    if (isLastChild) {
      className = `${className} ${classes.noBorder}`;
    }
    if (!importInProgress) {
      className = `${className} ${classes.withHover}`;
    }
    return className;
  };

  return (
    <ListItem
      key={project._id}
      className={getItemClassName()}
      onClick={() => goToProject(project.name)}
      data-testid="project-item"
    >
      <ListItemAvatar className={classes.avatarItem}>
        <>
          <Avatar className={classes.avatar}>
            <BeachAccessIcon />
            {importInProgress && (
              <CircularProgress className={classes.spinner} color="secondary" />
            )}
          </Avatar>
          {importInProgress && (
            <div className={classes.importing}>{translate('importing')}</div>
          )}
        </>
      </ListItemAvatar>
      <ListItemText primary={project.name} secondary={project.description} />

      {isAdmin && !importInProgress && (
        <ListItemSecondaryAction>
          <ProjectsListMenu
            aria-label="options"
            edge="end"
            projectName={project.name}
            setImportingFiles={setImportInProgress}
            isFilmSet={project.isFilmSet}
          />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

ProjectsListSingleRow.propTypes = {
  project: projectShape.isRequired,
  history: historyShape.isRequired,
  isLastChild: PropTypes.bool.isRequired,
};

export default withRouter(ProjectsListSingleRow);
