import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';
import MUIDataTable from 'mui-datatables';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import getTableDataForProjectQuery from '../../apollo/queries/getTableDataForProject.graphql';
import Loading from '../../common/Loading';
import { generateFilePath, generateProjectPath } from '../../common/routes';
import historyShape from '../../common/shapes/historyShape';
import useTranslation from '../common/useTranslation';
import {
  addLocalStorageFilter,
  clearProjectFilters,
  getInitialFilters,
  getInitialPage,
  getInitialSortOrder,
  resetProjectPage,
} from '../../common/utils/projectFilters';
import TableFilter from './TableFilter';

const TableView = ({ projectName, search, history, location }) => {
  const { filtered } = queryString.parse(location.search);
  const [currentPage, setCurrentPage] = useState(
    getInitialPage(projectName, filtered),
  );
  const [sortOrder, setSortOrder] = useState(
    getInitialSortOrder(projectName, filtered),
  );
  const [filters, setFilters] = useState(
    getInitialFilters(projectName, filtered),
  );
  const translate = useTranslation();

  const setFilteredUrl = () => {
    let url = `${generateProjectPath(projectName)}?filtered=true`;
    if (search) {
      url += `&search=${search}`;
    }
    history.push(url);
  };

  const useStyles = makeStyles(theme => ({
    image: {
      minWidth: 100,
      height: 80,
      objectFit: 'contain',
      justifyContent: 'flex-start',
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
  }));

  const classes = useStyles();

  const [getTableData, { loading, data }] = useLazyQuery(
    getTableDataForProjectQuery,
    {
      fetchPolicy: 'network-only',
    },
  );

  const updateTable = () => {
    const variables = {
      projectName,
      page: currentPage + 1,
    };
    if (search) {
      variables.search = search;
    }

    if (sortOrder.name) {
      variables.sortBy = sortOrder.name;
      variables.asc = sortOrder.direction === 'asc';
    }

    if (filters.length) {
      variables.filters = filters;
    }

    getTableData({
      variables,
    });
  };

  useEffect(() => {
    if (search) {
      setCurrentPage(0);
      resetProjectPage();
    }
    if (!filtered) {
      clearProjectFilters(projectName);
    }
    updateTable();
  }, [sortOrder, filters, currentPage, search]);

  if (loading) {
    return <Loading />;
  }

  const handleFilers = newFilters => {
    setFilteredUrl();
    setCurrentPage(0);
    resetProjectPage();
    const filter = {
      projectName,
      type: 'filters',
      filters: newFilters,
    };
    addLocalStorageFilter(filter);
    setFilters(newFilters);
  };

  const handleTableChange = async (action, tableState) => {
    if (['sort', 'changePage'].includes(action)) {
      setFilteredUrl();
      const filter = {
        projectName,
        type: action,
      };
      if (action === 'sort') {
        resetProjectPage();
        setCurrentPage(0);
        filter.sortBy = tableState.sortOrder.name;
        filter.asc = tableState.sortOrder.direction === 'asc';
      }
      if (action === 'changePage') {
        filter.page = tableState.page;
      }
      addLocalStorageFilter(filter);
    }

    if (
      action === 'propsUpdate' ||
      action === 'onFilterDialogOpen' ||
      action === 'filterChange'
    ) {
      return;
    }

    if (tableState.sortOrder.name) {
      await setSortOrder(tableState.sortOrder);
    }
    if (action === 'changePage') {
      setCurrentPage(tableState.page);
    }
  };

  const goToFile = id => {
    history.push(generateFilePath(projectName, id));
  };

  const filesData = get(data, 'getTableDataForProject', null);

  if (!filesData) {
    return null;
  }

  const columns = [
    {
      name: 'Thumbnail',
      options: {
        // eslint-disable-next-line react/display-name,react/no-multi-comp
        customBodyRender: value => {
          return value ? (
            <img src={value} alt="thumbnail" className={classes.image} />
          ) : null;
        },
        sort: false,
        filter: false,
      },
    },
    ...get(data, 'getTableDataForProject.columns', []),
    {
      name: '_id',
      options: {
        display: 'excluded',
        sort: false,
        filter: false,
      },
    },
  ];
  const rows = get(data, 'getTableDataForProject.data', []);
  const options = {
    selectableRows: 'none',
    print: false,
    download: false,
    search: false,
    serverSide: true,
    filter: false,
    count: filesData.total,
    page: currentPage,
    sortOrder,
    rowsPerPage: 20,
    rowsPerPageOptions: [20],
    onTableChange: handleTableChange,
    onRowClick: rowData => {
      const fileId = rowData.pop();
      goToFile(fileId);
    },
    sortThirdClickReset: true,
    viewColumns: false,
    textLabels: translate('tableTextLabels'),
    jumpToPage: true,
    setRowProps: row => {
      const name = row[row.length - 1];
      return { name };
    },
  };

  return (
    <Fragment>
      <TableFilter
        columns={columns}
        filters={filters}
        setFilters={handleFilers}
      />
      <MUIDataTable
        title={projectName}
        data={rows}
        columns={columns}
        options={options}
      />
    </Fragment>
  );
};

TableView.propTypes = {
  projectName: PropTypes.string.isRequired,
  history: historyShape.isRequired,
  search: PropTypes.string,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

TableView.defaultProps = {
  search: '',
};

export default withRouter(TableView);
