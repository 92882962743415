import Box from '@material-ui/core/Box';
import { CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const CircularProgressWithLabel = props => (
  <Box position="relative" display="inline-flex">
    <CircularProgress variant="determinate" {...props} />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant="caption"
        component="div"
        color="textSecondary"
      >{`${Math.round(props.value)}%`}</Typography>
    </Box>
  </Box>
);

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number,
};

CircularProgressWithLabel.defaultProps = {
  value: 0,
};
