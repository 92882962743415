import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import UserList from '../UserList';
import getProjectByNameQuery from '../../../apollo/queries/getProjectByName.graphql';
import removeProjectUserMutation from '../../../apollo/mutations/removeProjectUser.graphql';
import addUserToProjectMutation from '../../../apollo/mutations/addUserToProject.graphql';
import useTranslation from '../../common/useTranslation';
import userShape from '../../../common/shapes/userShape';

const ProjectUserList = ({ project }) => {
  const { name: projectName } = project;
  const translate = useTranslation();

  const [removeProjectUser] = useMutation(removeProjectUserMutation, {
    refetchQueries: () => [
      { query: getProjectByNameQuery, variables: { projectName } },
    ],
  });

  const [addUser, { loading: mutationLoading }] = useMutation(
    addUserToProjectMutation,
  );

  const deleteUser = async userId => {
    await removeProjectUser({ variables: { projectName, userId } });
  };

  const addUserToProject = async user => {
    if (user) {
      await addUser({
        variables: {
          userId: user._id,
          project: projectName,
        },
        refetchQueries: () => [
          { query: getProjectByNameQuery, variables: { projectName } },
        ],
      });
    }
  };

  return (
    <UserList
      handleDelete={deleteUser}
      users={project.userObjects}
      projectName={projectName}
      handleAdd={addUserToProject}
      // Hack - Autocomplete isn't really keen on clearing itself...
      isAddingUser={mutationLoading}
      title={translate('users')}
    />
  );
};

ProjectUserList.propTypes = {
  project: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    columns: PropTypes.string,
    isFilmSet: PropTypes.bool,
    userObjects: PropTypes.arrayOf(userShape),
  }).isRequired,
};

ProjectUserList.defaultProps = {};

export default ProjectUserList;
