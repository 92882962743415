/* eslint max-lines: off */

import textLabels from './tableTextLabels';

const dictionary = [
  {
    name: 'logout',
    pl: 'wyloguj',
    en: 'logout',
  },
  {
    name: 'settings',
    pl: 'Ustawienia',
    en: 'Settings',
  },
  {
    name: 'files',
    pl: 'Pliki',
    en: 'Files',
  },
  {
    name: 'projects',
    pl: 'projekty',
    en: 'projects',
  },
  {
    name: 'noProjects',
    pl: 'Nie znaleziono projektów',
    en: 'No projects found',
  },
  {
    name: 'signIn',
    pl: 'Logowanie',
    en: 'Sign in',
  },
  {
    name: 'password',
    pl: 'Hasło',
    en: 'Password',
  },
  {
    name: 'passwordConfirmation',
    pl: 'Potwierdź hasło',
    en: 'Password confirmation',
  },
  {
    name: 'passwordsDontMatch',
    pl: 'Podane hasła są różne!',
    en: 'Passwords do not match!',
  },
  {
    name: 'tokenInvalid',
    pl: 'Token stracił ważność!',
    en: 'Token is invalid',
  },
  {
    name: 'login',
    pl: 'Login',
    en: 'Login',
  },
  {
    name: 'email',
    pl: 'Email',
    en: 'Email',
  },
  {
    name: 'forgotPassword',
    pl: 'Zapomniałeś hasła?',
    en: 'Forgot password?',
  },
  {
    name: 'options',
    pl: 'Opcje',
    en: 'Options',
  },
  {
    name: 'importFiles',
    pl: 'Importuj pliki',
    en: 'Import files',
  },
  {
    name: 'permissions',
    pl: 'Uprawnienia',
    en: 'Permissions',
  },
  {
    name: 'importInProgress',
    pl: 'Import w trakcie',
    en: 'Import in progress',
  },
  {
    name: 'created',
    pl: 'Utworzono',
    en: 'Created',
  },
  {
    name: 'importing',
    pl: 'Importowanie...',
    en: 'Importing...',
  },
  {
    name: 'loggedOut',
    pl: 'Wylogowano!',
    en: 'Logged out!',
  },
  {
    name: 'addUser',
    pl: 'Dodaj użytkownika',
    en: 'Add user',
  },
  {
    name: 'create',
    pl: 'Stwórz',
    en: 'Create',
  },
  {
    name: 'createUser',
    pl: 'Stwórz użytkownika',
    en: 'Create user',
  },
  {
    name: 'userName',
    pl: 'Nazwa użytkownika',
    en: 'Username',
  },
  {
    name: 'instructionsSent',
    pl: 'Stworzono konto i wysłano instrukcje ustawienia hasła',
    en:
      'An account has been created and password setting instructions have been sent',
  },
  {
    name: 'emailInUse',
    pl: 'Email jest zajęty',
    en: 'Email is already in use',
  },
  {
    name: 'enterEmailAddress',
    pl: 'Podaj adres e-mail...',
    en: 'Enter e-mail address...',
  },
  {
    name: 'resetPassword',
    pl: 'Resetuj hasło',
    en: 'Reset password',
  },
  {
    name: 'passwordResetSuccess',
    pl: 'Link resetujący hasło wysłany!',
    en: 'Password reset link sent!',
  },
  {
    name: 'insertCorrectEmail',
    pl: 'Podaj prawidłowy email',
    en: 'Insert correct email',
  },
  {
    name: 'insertUserName',
    pl: 'Podaj nazwę użytkownika',
    en: 'Insert username',
  },
  { name: 'changePassword', pl: 'Zmień hasło', en: 'Change password' },
  {
    name: 'setPasswordSuccess',
    pl: 'Hasło zmienione!',
    en: 'Password changed!',
  },
  { name: 'users', pl: 'Użytkownicy', en: 'Users' },
  { name: 'noUsers', pl: 'Brak użytkowników', en: 'User list is empty' },
  {
    name: 'insertName',
    pl: 'Podaj nazwę',
    en: 'Insert name',
  },
  {
    name: 'projectCreated',
    pl: 'Projekt został stworzony.',
    en: 'Project has been created.',
  },
  {
    name: 'projectExists',
    pl: 'Projekt z tą nazwą już istnieje.',
    en: 'Project with this name already exists.',
  },
  {
    name: 'rememberFTP',
    pl:
      'Uwaga! Pamiętaj o założeniu folderu na serwerze FTP. Folder musi mieć tę samą nazwę co projekt.',
    en:
      'Attention! Remember to set up a folder on the FTP server. The folder must have the same name as the project.',
  },
  {
    name: 'add',
    pl: 'Dodaj',
    en: 'Add',
  },
  {
    name: 'addProject',
    pl: 'Dodaj projekt',
    en: 'Add project',
  },
  {
    name: 'projectName',
    pl: 'Nazwa projektu',
    en: 'Project name',
  },
  {
    name: 'language',
    pl: 'Język',
    en: 'Language',
  },
  {
    name: 'noUserFound',
    pl: 'Nie znaleziono użytkownika',
    en: 'User not found',
  },
  {
    name: 'invalidPassword',
    pl: 'Nieprawidłowe hasło.',
    en: 'Incorrect password.',
  },
  {
    name: 'quickSearch',
    pl: 'Szybkie wyszukiwanie...',
    en: 'Quick search...',
  },
  {
    name: 'resourceManager',
    pl: 'Menadżer zasobów',
    en: 'Resources manager',
  },
  {
    name: 'previewLink',
    pl: 'Link do podglądu',
    en: 'Preview link',
  },
  {
    name: 'savedToClipboard',
    pl: 'Skopiowano do schowka',
    en: 'Copied',
  },
  {
    name: 'sendLink',
    pl: 'Wyślij link do podglądu',
    en: 'Send preview link',
  },
  {
    name: 'nextVideo',
    pl: 'następne video',
    en: 'next video',
  },
  {
    name: 'previousVideo',
    pl: 'poprzednie video',
    en: 'previous video',
  },
  {
    name: 'next',
    pl: 'następna',
    en: 'next',
  },
  {
    name: 'previous',
    pl: 'poprzednia',
    en: 'previous',
  },
  { name: 'goBack', pl: 'Wróć', en: 'Go back' },
  {
    name: 'importSuccessful',
    pl: 'Zaimportowano pliki',
    en: 'Import successful',
  },
  {
    name: 'sorting',
    pl: 'Sortowanie',
    en: 'Sorting',
  },
  {
    name: 'projectEmpty',
    pl: 'Projekt jest pusty',
    en: 'Project is empty',
  },
  {
    name: 'emailSent',
    pl: 'E-mail wysłany',
    en: 'E-mail sent',
  },
  { name: 'latestFirst', pl: 'od najnowszych', en: 'latest first' },
  { name: 'oldestFirst', pl: 'od najstarszych', en: 'oldest first' },
  { name: 'filmSet', pl: 'Plan zdjęciowy', en: 'Film set' },
  {
    name: 'filmSetDay',
    pl: 'Wybierz dzień zdjęciowy',
    en: 'Pick film set day',
  },
  {
    name: 'pickSetDay',
    pl: 'Wybierz dzień zdjęciowy',
    en: 'Pick film set day',
  },
  { name: 'columns', pl: 'Kolumny', en: 'Columns' },
  { name: 'cancel', pl: 'Anuluj', en: 'Cancel' },
  { name: 'filterBy', pl: 'Filtruj po', en: 'Filter by' },
  { name: 'typeValue', pl: 'Wpisz wartość', en: 'Type value' },
  { name: 'date', pl: 'Data', en: 'Date' },
  { name: 'save', pl: 'Zapisz', en: 'Save' },
  { name: 'setPassword', pl: 'Ustaw hasło do konta', en: 'Set your password' },
  {
    name: 'columnsNote',
    pl:
      'Kolumny "Day", "Scene" oraz "Shot" są generowane automatycznie podczas importu plików, kolumna "Date" oznacza datę importowania pliku',
    en:
      'Columns "Day", "Scene" and "Shot" are generated automatically during import process, column "Date" is a date of file import',
  },
  {
    name: 'commaDivided',
    pl:
      'oddzielone przecinkiem nazwy kolumn z pliku ALE na podstawie których zostanie wygenerowana tabela (podgląd dodany automatycznie)',
    en:
      'type comma-separated ALE file columns which will be used to generate table (thumbnail is added automatically)',
  },
  {
    name: 'downloadUsers',
    pl: 'Uprawnienia do pobierania',
    en: 'File download',
  },
  textLabels,
];

// eslint-disable-next-line import/prefer-default-export
export { dictionary };
