import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import useTranslation from '../common/useTranslation';
import { filmSetDays } from '../../common/constans';

const FilmSetImportDialog = ({
  open,
  handleClose,
  filmSetDay,
  setFilmSetDay,
  handleImport,
}) => {
  const translate = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {translate('pickSetDay')}
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          // HACK: with this kind of id browser doesnt try to autocomplete input
          id={new Date().valueOf().toString()}
          options={filmSetDays}
          getOptionLabel={option => option.title}
          style={{ width: 400 }}
          onChange={(event, value) => {
            setFilmSetDay(value);
          }}
          autoComplete={false}
          renderInput={params => {
            return (
              <TextField
                {...params}
                id={new Date().valueOf().toString()}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
                label={translate('filmSetDay')}
                variant="outlined"
                style={{ width: 400 }}
              />
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {translate('cancel')}
        </Button>
        <Button onClick={handleImport} color="secondary" disabled={!filmSetDay}>
          {translate('importFiles')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FilmSetImportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setFilmSetDay: PropTypes.func.isRequired,
  handleImport: PropTypes.func.isRequired,
  filmSetDay: PropTypes.string,
};

FilmSetImportDialog.defaultProps = {
  filmSetDay: '',
};

export default FilmSetImportDialog;
