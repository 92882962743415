import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import styled from 'styled-components';
import moment from 'moment';

const MetaDisplay = ({ meta }) => {
  const metaPieces = [];
  if (isArray(meta)) {
    meta.forEach(({ k, v }) => {
      if (k === 'Date') {
        metaPieces.push({
          key: k,
          value: moment(v).format('DD.MM.YYYY hh:MM'),
        });
        return;
      }
      metaPieces.push({ key: k, value: v });
    });
  } else {
    Object.keys(meta).forEach(key => {
      let humanKey = '';
      if (key === 'movieTitle') {
        humanKey = 'Movie title';
      } else {
        humanKey = 'Movie format';
      }
      metaPieces.push({ key: humanKey, value: meta[key] });
    });
  }
  return (
    <Wrapper>
      {metaPieces.map(metaPiece => (
        <TextWrapper key={metaPiece.key}>
          <strong>{metaPiece.key}: </strong>
          {metaPiece.value}
        </TextWrapper>
      ))}
    </Wrapper>
  );
};

MetaDisplay.propTypes = {
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      v: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      k: PropTypes.string,
    }),
  ),
};

MetaDisplay.defaultProps = {
  meta: [],
};

const Wrapper = styled.div`
  margin-top: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;
const TextWrapper = styled.div`
  text-overflow: ellipsis;
  max-width: 100%;
`;

export default MetaDisplay;
