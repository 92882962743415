import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import LocalizationHelper from './localizationHelper';

export default function useTranslation() {
  const { language } = useContext(UserContext);
  console.log('language BOOM', language);
  const translate = new LocalizationHelper(language);
  return translate.getText;
}
