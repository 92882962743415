import { useMutation } from '@apollo/react-hooks';
import React, { useState } from 'react';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import createProjectMutation from '../../../apollo/mutations/createProject.graphql';
import useSnackbar from '../../common/Snackbar/useSnackbar';
import useTranslation from '../../common/useTranslation';
import UserList from '../../Projects/UserList';
import MainSettingsPanel from './MainSettingsPanel';
import ProjectForm from './ProjectForm';

export default function CreateProject() {
  const [users, setUsers] = useState([]);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const snackbar = useSnackbar();
  const translate = useTranslation();

  const [createProject] = useMutation(createProjectMutation);

  // eslint-disable-next-line consistent-return
  const handleSubmit = async (project, closeParent) => {
    try {
      await createProject({
        variables: {
          ...project,
          users: users.map(({ _id }) => _id),
        },
      });
      snackbar('success', translate('projectCreated'));
      closeParent();
    } catch (err) {
      snackbar('error', err.message);
    }
  };

  const handleUserAdd = async user => {
    // Hack - Autocomplete isn't really keen on clearing itself...
    await setIsAddingUser(true);
    if (user) {
      const currentUsers = [...users];
      currentUsers.push({
        _id: user._id,
        name: user.name,
        email: user.email,
      });
      setUsers(currentUsers);
    }
    setIsAddingUser(false);
  };

  const handleUserDelete = userId => {
    const currentUsers = [...users];
    const index = currentUsers.findIndex(({ _id }) => _id === userId);
    currentUsers.splice(index, 1);
    setUsers(currentUsers);
  };

  return (
    <MainSettingsPanel
      icon={<LibraryAddIcon />}
      headerText={translate('addProject')}
    >
      {closeParent => (
        <div>
          <UserList
            handleDelete={handleUserDelete}
            handleAdd={handleUserAdd}
            users={users}
            isAddingUser={isAddingUser}
            title={translate('users')}
          />

          <ProjectForm
            handleSubmit={project => handleSubmit(project, closeParent)}
          />
        </div>
      )}
    </MainSettingsPanel>
  );
}
