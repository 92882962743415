const addLocalStorageFilter = filter => {
  let filters = [filter];
  const currentFiltersString = localStorage.getItem('filters');
  if (currentFiltersString) {
    const currentFilters = JSON.parse(currentFiltersString);
    const currentFilterIndex = currentFilters.findIndex(
      ({ type }) => type === filter.type,
    );
    if (currentFilterIndex >= 0) {
      currentFilters[currentFilterIndex] = filter;
    } else {
      currentFilters.push(filter);
    }
    filters = currentFilters;
  }

  localStorage.setItem('filters', JSON.stringify(filters));
};

const getFiltersForProject = projectName => {
  const currentFiltersString = localStorage.getItem('filters');
  if (currentFiltersString) {
    const currentFilters = JSON.parse(currentFiltersString);
    return currentFilters.filter(
      filterEntry => filterEntry.projectName === projectName,
    );
  }
  return [];
};

const clearProjectFilters = projectName => {
  const currentFiltersString = localStorage.getItem('filters');
  if (currentFiltersString) {
    const currentFilters = JSON.parse(currentFiltersString);
    const cleanFilters = currentFilters.filter(
      filterEntry => filterEntry.projectName !== projectName,
    );
    localStorage.setItem('filters', JSON.stringify(cleanFilters));
  }
};

const getInitialSortOrder = (projectName, filtered) => {
  if (filtered) {
    const sortOrder = getFiltersForProject(projectName).find(
      ({ type }) => type === 'sort',
    );
    if (sortOrder) {
      return {
        name: sortOrder.sortBy,
        direction: sortOrder.asc ? 'asc' : 'desc',
      };
    }
  }
  return {};
};

const getInitialPage = (projectName, filtered) => {
  if (filtered) {
    const pageFilter = getFiltersForProject(projectName).find(
      ({ type }) => type === 'changePage',
    );
    if (pageFilter) {
      return pageFilter.page;
    }
  }
  return 0;
};

const getInitialFilters = (projectName, filtered) => {
  if (filtered) {
    const pageFilter = getFiltersForProject(projectName).find(
      ({ type }) => type === 'filters',
    );
    if (pageFilter) {
      return pageFilter.filters;
    }
  }
  return [];
};

const resetProjectPage = projectName => {
  const currentFiltersString = localStorage.getItem('filters');
  if (currentFiltersString) {
    const currentFilters = JSON.parse(currentFiltersString);
    const cleanFilters = currentFilters.filter(
      filterEntry =>
        filterEntry.projectName !== projectName &&
        filterEntry.type !== 'changePage',
    );
    localStorage.setItem('filters', JSON.stringify(cleanFilters));
  }
};

export {
  addLocalStorageFilter,
  getFiltersForProject,
  clearProjectFilters,
  getInitialSortOrder,
  getInitialPage,
  getInitialFilters,
  resetProjectPage,
};
