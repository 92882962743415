import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import UserList from '../UserList';
import removeDownloadUserMutation from '../../../apollo/mutations/removeDownloadUser.graphql';
import addDownloadUserToProjectMutation from '../../../apollo/mutations/addDownloadUserToProject.graphql';
import getProjectByNameQuery from '../../../apollo/queries/getProjectByName.graphql';
import useTranslation from '../../common/useTranslation';
import userShape from '../../../common/shapes/userShape';

const DownloadUserList = ({ project }) => {
  const { name: projectName } = project;
  const translate = useTranslation();

  const [addUser, { loading: mutationLoading }] = useMutation(
    addDownloadUserToProjectMutation,
  );

  const [removeDownloadUser] = useMutation(removeDownloadUserMutation, {
    refetchQueries: () => [
      { query: getProjectByNameQuery, variables: { projectName } },
    ],
  });

  const deleteUser = async userId => {
    await removeDownloadUser({ variables: { projectName, userId } });
  };

  const addDownloadUser = async user => {
    if (user) {
      await addUser({
        variables: {
          userId: user._id,
          projectName,
        },
        refetchQueries: () => [
          { query: getProjectByNameQuery, variables: { projectName } },
        ],
      });
    }
  };

  return (
    <UserList
      handleDelete={deleteUser}
      users={project.downloadUsers}
      projectName={projectName}
      handleAdd={addDownloadUser}
      // Hack - Autocomplete isn't really keen on clearing itself...
      isAddingUser={mutationLoading}
      title={translate('downloadUsers')}
      usersToSelect={project.userObjects}
    />
  );
};

DownloadUserList.propTypes = {
  project: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    columns: PropTypes.string,
    isFilmSet: PropTypes.bool,
    userObjects: PropTypes.arrayOf(userShape),
    downloadUsers: PropTypes.arrayOf(userShape),
  }).isRequired,
};

DownloadUserList.defaultProps = {};

export default DownloadUserList;
