import React, { Fragment, useState, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import queryString from 'query-string';
import Pagination from 'react-paginate';
import get from 'lodash/get';
import getFolderFilesByPathQuery from '../../apollo/queries/getFolderFilesByPath.graphql';
import fileSearchQuery from '../../apollo/queries/fileSearch.graphql';
import useSnackbar from '../common/Snackbar/useSnackbar';
import historyShape from '../../common/shapes/historyShape';
import SearchBar from '../common/SearchBar';
import PathBreadcrumbs from '../common/PathBreadcrumbs';
import useTranslation from '../common/useTranslation';
import { generateProjectPath } from '../../common/routes';
import getProjectByNameQuery from '../../apollo/queries/getProjectByName.graphql';
import Loading from '../../common/Loading';
import { resetProjectPage } from '../../common/utils/projectFilters';
import FilesList from './FilesList';
import FoldersTree from './FoldersTree';
import ContentSort from './ContentSort';
import TableView from './TableView';

const useStyles = makeStyles(theme => ({
  topBar: {
    padding: theme.spacing(1, 2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
    borderRadius: 5,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)',
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(3),
    borderRadius: 5,
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& li': {
      cursor: 'pointer',
      display: 'inline',
      padding: '10px',
      border: '1px solid',
      borderColor: theme.palette.primary.light,
    },
  },
  activePagination: {
    backgroundColor: theme.palette.primary.light,
  },
  rightWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  viewText: {
    marginRight: '20px',
  },
  tableWrapper: {
    marginTop: theme.spacing(2),
  },
}));

function ProjectView(props) {
  const { search } = props.location;
  const {
    path: pathRedirect,
    scrollTo,
    search: urlSearch,
    page: urlPage,
    sort = 'latestFirst',
  } = queryString.parse(search);
  const [isInSearchMode, setSearchMode] = useState(!!urlSearch);
  const [projectFiles, setProjectFiles] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [currentPath, setCurrentPath] = useState('');
  const [sortOrder, setSortOrder] = useState(sort);
  const translate = useTranslation();
  const classes = useStyles();
  const { projectName } = props.match.params;
  const snackBar = useSnackbar();
  const { data: projectData, loading: projectLoading } = useQuery(
    getProjectByNameQuery,
    {
      variables: {
        projectName,
      },
    },
  );

  const isFilmSet = get(projectData, 'getProjectByName.isFilmSet', false);

  const [highlightedItem] = useState(scrollTo);

  const [getFolderFiles, { error }] = useLazyQuery(getFolderFilesByPathQuery, {
    fetchPolicy: 'network-only',
    onCompleted: queryData => {
      setProjectFiles(queryData.getFolderFilesByPath);
    },
  });

  const [searchFiles, { loading: searchLoading }] = useLazyQuery(
    fileSearchQuery,
    {
      onCompleted: queryData => {
        setProjectFiles(queryData.fileSearch.files);
        setPagination({
          ...pagination,
          totalPages: queryData.fileSearch.pagesCount,
        });
      },
    },
  );

  if (error) {
    if (error.message === 'GraphQL error: Not authorized!') {
      snackBar('error', error.message);
      return <Redirect to="/login" />;
    }
  }

  const handleSortChange = async order => {
    if (isInSearchMode) {
      props.history.push(
        `${generateProjectPath(
          projectName,
        )}?search=${urlSearch}&page=1&sort=${order}`,
      );
      searchFiles({
        variables: { projectName, urlPage, query: urlSearch, sort: order },
      });
    }
    setSortOrder(order);
    setPagination({ ...pagination, currentPage: 1 });
  };

  const handleFolderChange = path => {
    setSearchMode(false);
    setPagination(null);
    props.history.push(`${generateProjectPath(projectName)}`);
    setCurrentPath(path);
    getFolderFiles({
      variables: { projectName, path },
    });
  };

  const handleSearch = (searchQuery, page = 1) => {
    if (isFilmSet) {
      resetProjectPage();
      const { filtered } = queryString.parse(search);
      let path = `${generateProjectPath(projectName)}?search=${searchQuery}`;
      if (filtered) {
        path += '&filtered=true';
      }
      props.history.push(path);
      return;
    }
    props.history.push(
      `${generateProjectPath(
        projectName,
      )}?search=${searchQuery}&page=${page}&sort=${sortOrder}`,
    );
    setSearchMode(true);
    setCurrentPath(null);
    setPagination({ ...pagination, currentPage: page });
    searchFiles({
      variables: { projectName, page, query: searchQuery, sort: sortOrder },
    });
  };

  useEffect(() => {
    if (isInSearchMode && !pagination && !searchLoading) {
      handleSearch(urlSearch, parseInt(urlPage, 10));
    }
  }, [urlSearch, isInSearchMode]);
  if (scrollTo) {
    setTimeout(() => {
      let element = document.getElementById(scrollTo);
      if (!element) {
        const elementByName = document.getElementsByName(scrollTo);
        if (elementByName.length) {
          [element] = elementByName;
        } else {
          return;
        }
      }
      window.scrollTo({
        top: element.getBoundingClientRect().top - 500,
        behavior: 'smooth',
      });
    }, 500);
  }

  if (projectLoading) {
    return <Loading />;
  }

  return (
    <Fragment>
      <SearchBar
        projectName={projectName}
        history={props.history}
        handleSearch={handleSearch}
        sortOrder={sortOrder}
        urlSearch={urlSearch}
      />
      <Container>
        {projectFiles.length || isFilmSet ? (
          <div className={classes.topBar}>
            <PathBreadcrumbs
              projectName={projectName}
              path={currentPath}
              displaySort
              isFilmSet={isFilmSet}
            />
            {isFilmSet ? null : (
              <ContentSort
                changeSort={handleSortChange}
                sortOrder={sortOrder}
              />
            )}
          </div>
        ) : null}
        {isFilmSet ? (
          <div className={classes.tableWrapper}>
            <TableView
              projectName={projectName}
              history={props.history}
              search={urlSearch}
            />
          </div>
        ) : (
          <Fragment>
            <Grid container spacing={3}>
              <FoldersTree
                projectName={projectName}
                handleFolderChange={handleFolderChange}
                initialPath={pathRedirect}
                history={props.history}
                inSearchMode={isInSearchMode}
              />
              <FilesList
                files={projectFiles}
                projectName={projectName}
                history={props.history}
                highlightedItem={highlightedItem}
                sortOrder={sortOrder}
                loading={projectLoading}
              />
            </Grid>
            {pagination && pagination.totalPages ? (
              <Pagination
                pageCount={pagination.totalPages}
                pageRangeDisplayed={10}
                marginPageDisplayed={2}
                onPageChange={({ selected }) => {
                  const { search: searchQuery } = queryString.parse(search);
                  if (searchQuery) {
                    handleSearch(searchQuery, selected + 1);
                  } else {
                    props.history.push(generateProjectPath(projectName));
                  }
                }}
                containerClassName={classes.pagination}
                activeClassName={classes.activePagination}
                // react-paginate counts pages from 0, initialPage fires onPageChange
                initialPage={parseInt(urlPage, 10) - 1}
                nextLabel={translate('next')}
                previousLabel={translate('previous')}
                forcePage={pagination.currentPage - 1}
              />
            ) : null}
          </Fragment>
        )}
      </Container>
    </Fragment>
  );
}

export default ProjectView;

ProjectView.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: historyShape.isRequired,
};
