import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Paper from '../../common/Paper';
import useTranslation from '../../common/useTranslation';

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(3),
  },
  textField: {
    minWidth: theme.spacing(42),
  },
  button: {
    margin: theme.spacing(4),
    marginLeft: 0,
  },
  floatingLabelFocusStyle: {
    color: theme.palette.secondary.light,
  },
  columnNames: {
    fullWidth: true,
  },
}));

const getColumns = (columnNames = '') => {
  return columnNames
    .split(',')
    .map(colName => colName.trim())
    .filter(colName => colName);
};

const ProjectForm = ({ handleSubmit, project }) => {
  const classes = useStyles();
  const [isFilmSet, setFilmSet] = useState(project.isFilmSet);
  const [name, setName] = useState(project.name);
  const [columnNames, setColumnNames] = useState(project.columns);
  const translate = useTranslation();
  const [nameError, setNameError] = useState('');

  const handleInputChange = event => {
    if (event.target.name === 'name') {
      setName(event.target.value);
    } else {
      setColumnNames(event.target.value);
    }

    if (nameError) {
      setNameError('');
    }
  };

  const formHasErrors = () => {
    let hasErrors = false;
    if (!name) {
      setNameError('insertName');
      hasErrors = true;
    }
    return hasErrors;
  };

  const parseColumnNames = () => {
    const columns = getColumns(columnNames);
    if (columnNames) {
      return columns.map(column => <Chip key={column} label={column} />);
    }
    return null;
  };

  const submitForm = event => {
    event.preventDefault();
    if (formHasErrors(name)) {
      return;
    }
    const currentProject = {
      name,
      isFilmSet,
    };

    if (isFilmSet) {
      currentProject.columns = getColumns(columnNames);
    }

    if (project._id) {
      currentProject.id = project._id;
    }

    handleSubmit(currentProject);
  };

  const changeFilmSet = () => {
    if (!isFilmSet) {
      setColumnNames(
        'Day, Episode, Scene, Shot, Take, Name, Start, Duration, Date',
      );
    }
    setFilmSet(!isFilmSet);
  };

  return (
    <form onSubmit={submitForm}>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          margin="normal"
          InputLabelProps={{
            style: {
              color: '#fff5f8',
            },
          }}
          onChange={handleInputChange}
          error={!!nameError}
          helperText={nameError && translate(nameError)}
          label={translate('projectName')}
          name="name"
          value={name}
        />
        <br />
        <Typography variant="subtitle1" color="secondary">
          {translate('rememberFTP')}
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={isFilmSet}
              onChange={changeFilmSet}
              name="filmSet"
            />
          }
          label={translate('filmSet')}
        />
        <br />
        {isFilmSet ? (
          <Fragment>
            <TextField
              className={classes.textField}
              margin="normal"
              InputLabelProps={{
                style: {
                  color: '#fff5f8',
                },
              }}
              autoComplete="off"
              onChange={handleInputChange}
              helperText={translate('commaDivided')}
              label={translate('columns')}
              name="columnNames"
              value={columnNames}
            />
            <Paper>{parseColumnNames(columnNames)}</Paper>
            <Paper>{translate('columnsNote')}</Paper>
          </Fragment>
        ) : null}
        <br />
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={submitForm}
        >
          {project._id ? translate('save') : translate('add')}
        </Button>
      </div>
    </form>
  );
};

ProjectForm.propTypes = {
  project: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    columns: PropTypes.string,
    isFilmSet: PropTypes.bool,
  }),
  handleSubmit: PropTypes.func.isRequired,
};

ProjectForm.defaultProps = {
  project: {
    name: '',
    columns: 'Day, Episode, Scene, Shot, Take, Name, Start, Duration, Date',
    isFilmSet: false,
  },
};

export default ProjectForm;
