const languages = {
  POLISH: 'pl',
  ENGLISH: 'en',
};

const array = [...new Array(100).keys()];
const oneToNinetyNine = array.map((empty, index) => {
  const value = index.toString().length === 1 ? `0${index}` : index.toString();
  return {
    title: value,
    value,
  };
});

const filmSetDays = [
  ...oneToNinetyNine,
  { title: 'Tests', value: 'Tests' },
  { title: 'Screener', value: 'Screener' },
  { title: 'Feature', value: 'Feature' },
  { title: 'Cuts', value: 'Cuts' },
  { title: 'Stock', value: 'Stock' },
  { title: 'Archival', value: 'Archival' },
];

export { languages, filmSetDays };
