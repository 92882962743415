import React, { Fragment } from 'react';
import FaceIcon from '@material-ui/icons/Face';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useQuery } from '@apollo/react-hooks';
import userShape from '../../common/shapes/userShape';
import useTranslation from '../common/useTranslation';
import getUsersQuery from '../../apollo/queries/getUsers.graphql';
import Loading from '../../common/Loading';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'block',
    padding: theme.spacing(3),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const useAutoCompleteStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    '& .MuiFormLabel-root': {
      color: theme.palette.common.white,
    },
  },
  inputRoot: {
    border: '1px solid',
    borderColor: theme.palette.secondary.light,
  },
  groupLabel: {
    color: theme.palette.common.white,
  },
  listbox: {
    backgroundColor: theme.palette.primary.light,
  },
  popupIndicator: {
    color: theme.palette.common.white,
  },
}));

export default function UserList({
  users,
  handleDelete,
  handleAdd,
  isAddingUser,
  title,
  usersToSelect,
}) {
  const classes = useStyles();
  const autocompleteClasses = useAutoCompleteStyles();
  const translate = useTranslation();
  const { data, loading } = useQuery(getUsersQuery);

  let allUsers = [...usersToSelect];

  if (!loading && !usersToSelect.length) {
    allUsers = data.getUsers.filter(
      user => !users.find(projectUser => projectUser._id === user._id),
    );
  }

  return (
    <div className={classes.main}>
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      {users.length ? null : translate('noUsers')}
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          {users.map(option => (
            <Chip
              color="secondary"
              key={option._id}
              icon={<FaceIcon />}
              label={`${option.name} - ${option.email}`}
              onDelete={() => {
                handleDelete(option._id);
              }}
            />
          ))}
          {/* Hack - Autocomplete isn't really keen on clearing itself... */}
          {!isAddingUser && (
            <Autocomplete
              id="user-list"
              options={allUsers}
              getOptionLabel={option => `${option.name} - ${option.email}`}
              onChange={(event, selectedUser) => {
                handleAdd(selectedUser);
              }}
              disableClearable
              clearOnEscape
              classes={autocompleteClasses}
              renderInput={params => (
                <TextField
                  {...params}
                  label={translate('addUser')}
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                />
              )}
            />
          )}
        </Fragment>
      )}
    </div>
  );
}

UserList.propTypes = {
  users: PropTypes.arrayOf(userShape),
  handleDelete: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  isAddingUser: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  usersToSelect: PropTypes.arrayOf(userShape),
};

UserList.defaultProps = {
  users: [],
  usersToSelect: [],
};
