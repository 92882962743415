import React, { useState } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import useTranslation from './useTranslation';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 16,
    height: 60,
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    alignItems: 'center',
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)',
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.03),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.05),
    },
    marginLeft: 0,
    marginRight: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    fontFamily: 'Open Sans',
    fontWeight: 300,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 300,
      '&:focus': {
        width: 400,
      },
    },
  },
}));

export default function SearchBar({ handleSearch, urlSearch }) {
  const classes = useStyles();
  const translate = useTranslation();
  const [searchQuery, setSearchQuery] = useState(urlSearch);

  const search = () => {
    handleSearch(searchQuery);
  };

  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.right}>
          <div className={classes.search}>
            <InputBase
              placeholder={translate('quickSearch')}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  search();
                }
              }}
              value={searchQuery}
              onChange={event => setSearchQuery(event.target.value)}
            />
          </div>
          <SearchIcon className={classes.searchIcon} onClick={search} />
        </div>
      </Container>
    </div>
  );
}

SearchBar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  urlSearch: PropTypes.string,
};

SearchBar.defaultProps = {
  urlSearch: '',
};
