import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
// import streamSaver from 'streamsaver';
import axios from 'axios';
import jsFileDownload from 'js-file-download';
import fileShape from '../../common/shapes/fileShape';
import { CircularProgressWithLabel } from '../common/CircularProgressWithLabel';

const DownloadButton = ({ file }) => {
  const [progress, setProgress] = useState(0);

  const downloadFile = () => {
    const remoteFileName = file.url.substring(
      file.url.lastIndexOf('/') + 1,
      file.url.length,
    );

    const url =
      process.env.GRAPHQL === 'local'
        ? '/file'
        : 'https://serwer1916919.home.pl/fisheye';
    // setInProgress(true);
    // try {
    //   const remoteFileName = file.url.substring(
    //     file.url.lastIndexOf('/') + 1,
    //     file.url.length,
    //   );
    //   const fileStream = streamSaver.createWriteStream(file.name);
    //   const url = `/file/${remoteFileName}`;
    //   fetch(url)
    //     .then(response => {
    //       window.writer = fileStream.getWriter();
    //       const reader = response.body.getReader();
    //       const pump = () =>
    //         reader
    //           .read()
    //           .then(res =>
    //             res.done
    //               ? window.writer.close()
    //               : window.writer.write(res.value).then(pump),
    //           )
    //           .catch(e => {
    //             console.log('e BOOM', e);
    //           });
    //
    //       pump();
    //     })
    //     .catch(err => {
    //       console.log('err BOOM', err);
    //     })
    //     .finally(() => {
    //       console.log('finall BOOM');
    //       setInProgress(false);
    //     });
    // } catch (e) {
    //   console.log('e BOOM', e);
    // }

    // saveAs(`/file/${remoteFileName}`, file.name);
    axios
      .get(`${url}/${remoteFileName}`, {
        crossdomain: true,
        responseType: 'blob',
        onDownloadProgress: event => {
          const currentProgress = Math.floor(
            (event.loaded / event.total) * 100,
          );
          if (currentProgress === 100) {
            setProgress(0);
            return;
          }
          setProgress(currentProgress);
        },
      })
      .then(res => {
        jsFileDownload(res.data, `${file.name}`);
      })
      .catch(err => {
        console.log('err BOOM', err);
      });
  };

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
        height: 40,
      }}
    >
      <Button
        style={{
          backgroundColor: 'rgba(247, 247, 248, 0.05)',
          color: 'rgba(247, 247, 248, 0.25)',
        }}
        variant="contained"
        onClick={downloadFile}
      >
        Pobierz plik
      </Button>
      {!!progress && (
        <CircularProgressWithLabel
          style={{ marginLeft: 5 }}
          size={40}
          color="secondary"
          value={progress}
        />
      )}
      {/* {inProgress && ( */}
      {/*  <CircularProgress */}
      {/*    style={{ marginLeft: 5 }} */}
      {/*    size={40} */}
      {/*    color="secondary" */}
      {/*  /> */}
      {/* )} */}
    </Box>
  );
};

DownloadButton.propTypes = {
  file: fileShape.isRequired,
};

export default DownloadButton;
