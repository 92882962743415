import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { makeStyles, TextField } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import Paper from '../common/Paper';
import useTranslation from '../common/useTranslation';

const useStyles = makeStyles(theme => ({
  topBar: {
    padding: theme.spacing(1, 2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)',
    width: '100%',
  },
  select: {
    '& .MuiInput-root': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  textField: {
    marginTop: theme.spacing(3),
    '& .MuiInputBase-input': {
      '#picker': {
        filter: 'invert(1)',
      },
    },
  },
  MuiFormLabel: {
    root: {
      backgroundColor: theme.palette.primary.light,
      marginTop: theme.spacing(3),
    },
  },
  datepicker: {
    '& .MuiButton-label': {
      color: theme.palette.common.white,
    },
  },
}));

const TableFilter = ({ columns, filters, setFilters }) => {
  const translate = useTranslation();
  const classes = useStyles();
  const [currentColumn, setCurrentColumn] = useState('');
  const [value, setValue] = useState('');
  const usedFilters = filters.map(({ name }) => name);
  const cleanColumns = [...columns].filter(
    column => !usedFilters.includes(column),
  );
  cleanColumns.splice(0, 1);
  cleanColumns.splice(cleanColumns.length - 1, 1);

  const setColumn = (event, child) => {
    let newValue = '';
    if (child.props.value === 'Date') {
      newValue = moment();
    }
    setValue(newValue);
    setCurrentColumn(child.props.value);
  };

  const changeValue = event => {
    setValue(event.target.value);
  };

  const handleFilterAdd = event => {
    event.preventDefault();
    setFilters([...filters, { name: currentColumn, value }]);
    setCurrentColumn('');
    setValue('');
  };

  const handleFilterDelete = filterName => {
    const index = filters.findIndex(filter => filter.name === filterName);
    const newFilters = [...filters];
    newFilters.splice(index, 1);
    setFilters(newFilters);
  };

  const setFilterDate = momentDate => {
    const date = momentDate.format('YYYY-MM-DD');
    setFilters([...filters, { name: currentColumn, value: date }]);
    setCurrentColumn('');
    setValue('');
  };

  // eslint-disable-next-line react/no-multi-comp
  const getFilterValueField = column => {
    if (column === 'Date') {
      return (
        <DatePicker
          className={classes.textField}
          autoOk
          fullWidth
          value={value}
          onChange={setFilterDate}
          format={'DD-MM-YYYY'}
        />
      );
    }
    return (
      <TextField
        className={classes.textField}
        fullWidth
        placeholder={translate('typeValue')}
        onChange={changeValue}
        value={value}
      />
    );
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={classes.topBar}>
        <Container>
          <Paper style={{ backgroundColor: '#131A22' }}>
            <InputLabel id="column-label">{translate('filterBy')}</InputLabel>
            <Select
              labelId="column-label"
              id="column"
              placeholder={translate('filterBy')}
              fullWidth
              value={currentColumn}
              onChange={setColumn}
              className={classes.select}
            >
              {cleanColumns.map(column => (
                <MenuItem value={column} key={column}>
                  {column}
                </MenuItem>
              ))}
            </Select>
            {currentColumn ? (
              <form onSubmit={handleFilterAdd}>
                {getFilterValueField(currentColumn)}
              </form>
            ) : null}
          </Paper>
          <Paper style={{ backgroundColor: '#131A22', marginTop: 10 }}>
            {filters.map(filter => (
              <Chip
                key={filter.name}
                color="secondary"
                label={`${filter.name} - ${filter.value}`}
                onDelete={() => handleFilterDelete(filter.name)}
              />
            ))}
          </Paper>
        </Container>
      </div>
    </MuiPickersUtilsProvider>
  );
};

TableFilter.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ name: PropTypes.string, options: PropTypes.object }),
    ]),
  ).isRequired,
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

TableFilter.defaultProps = {
  filters: [],
};

export default TableFilter;
