import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import historyShape from '../../common/shapes/historyShape';
import fileShape from '../../common/shapes/fileShape';
import sortFiles from '../../common/utils/sortFiles';
import Paper from '../common/Paper';
import useTranslation from '../common/useTranslation';
import FileThumbnail from './FileThumbnail';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(3),
    borderRadius: 5,
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)',
  },
}));

function FilesList({
  files = [],
  history,
  highlightedItem,
  sortOrder,
  loading,
}) {
  const classes = useStyles();
  const translate = useTranslation();

  const sortedFiles = sortFiles(files, sortOrder);

  if (!sortedFiles.length && !loading) {
    return (
      <Grid item xs={12}>
        <Paper className={classes.paper}>{translate('projectEmpty')}</Paper>
      </Grid>
    );
  }
  return (
    <Grid item md={8} xs={12}>
      <div className={classes.root}>
        {sortedFiles.map(file => (
          <FileThumbnail
            file={file}
            key={file._id}
            history={history}
            highlight={file._id === highlightedItem}
          />
        ))}
      </div>
    </Grid>
  );
}

FilesList.propTypes = {
  history: historyShape.isRequired,
  files: PropTypes.arrayOf(fileShape).isRequired,
  highlightedItem: PropTypes.string,
  sortOrder: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

FilesList.defaultProps = {
  highlightedItem: null,
};

export default withRouter(FilesList);
